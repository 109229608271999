import { useEffect, useState } from "react";
import { getDeployed, getBuilt } from "../utils";
import LatestVersion from "./LatestVersion.js";
import Deploy from "./Deploy.js";
import Status from "./Status";

const Home = () => {
    const [version, setVersion] = useState([]);
    const [versionStaging, setVersionStaging] = useState([]);
    const [logs, setLogs] = useState([]);
    const [versionsBuilt, setVersionsBuilt] = useState([]);
    const [versionsDeployed, setVersionsDeployed] = useState([]);
    const [versions, setVersions] = useState([]);
    const [serviceStatus, setServiceStatus] = useState([true]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetchAll = () => {
            fetch('/api/version/prod')
                .then((response) => response.json())
                .then((data) => {
                    setVersion(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });

            fetch('/api/version/staging')
                .then((response) => response.json())
                .then((data) => {
                    setVersionStaging(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });

            getDeployed().then((data) => {
                // loop over data.vers and remove versions that are not in semver format
                data.vers = data.vers.filter((v) => {
                    return v.match(/^v\d+\.\d+\.\d+$/);
                });

                setVersions(data.vers);

                // only show elements that match data.vers
                data = data.filter((d) => {
                    return data.vers.includes(d.version);
                });

                setVersionsDeployed(data);

                getBuilt().then((data) => {
                    // loop over each data index
                    data.forEach(element => {
                        // check if the version is in semver format
                        if (!element.status.version.match(/^v\d+\.\d+\.\d+$/)) {
                            // if not, remove it
                            data.splice(data.indexOf(element), 1);
                        }
                    });

                    setVersionsBuilt(data);
                }).catch((err) => {
                    console.log(err.message);
                });
            }).catch((err) => {
                console.log(err.message);
            });

            fetch('/api/logs?page=1')
                .then((response) => response.json())
                .then((data) => {
                    setLogs(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });

            fetch('/api/service_status')
                .then((response) => response.json())
                .then((data) => {
                    setServices(data);

                    const mysql_status = data.mysql.online;
                    const memcached_status = data.memcached.online;
                    const redis_status = data.redis.online;

                    const service_status = mysql_status
                        && memcached_status
                        && redis_status;
                    setServiceStatus(service_status);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        };

        fetchAll(); // Initial fetch
        const intervalId = setInterval(fetchAll, 4000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <div className="bg-slate-200 p-3 flex">
                <div className="flex-auto"></div>
                <div className="flex-auto justify-end">
                    <LatestVersion
                        version={version}
                        versionStaging={versionStaging} />
                </div>
            </div>
            <div className="p-3">
                <Status
                    services={services}
                    serviceStatus={serviceStatus} />

                <Deploy
                    logs={logs}
                    version={version}
                    versions={versions}
                    versionsBuilt={versionsBuilt}
                    versionsDeployed={versionsDeployed}
                    versionStaging={versionStaging} />
            </div>
        </>
    );
};

export default Home;
