import { Link } from "react-router-dom";

export default function Status(props) {
    return (
        <>
            {!props.serviceStatus && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-5 mt-5 mb-5 ml-3 mr-3 rounded relative" role="alert">
                    <strong className="font-bold pr-2">System Impaired!</strong>
                    <span className="block sm:inline">
                        {props.services.mysql.online ? '' : 'MySQL (production) appears to be offline. '}
                        {props.services.redis.online ? '' : 'Redis (production) appears to be offline. '}
                        {props.services.memcached.online ? '' : 'Memcached (production) appears to be offline. '}
                        <Link to="/service_status" className="underline">View service status</Link>
                    </span>
                </div>
            )}
        </>
    );
}
