import { useState, useEffect } from "react";
import Spinner from "./Spinner";

const ServiceStatus = () => {
    const [services, setServices] = useState([]);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        let interval = setInterval(() => {
            fetch('/api/service_status')
                .then((response) => response.json())
                .then((data) => {
                    setServices(data);
                    setSpinner(false);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    if (!spinner) {
        return (
            <div className="container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Service Status</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div>
                    <div className="overflow-hidden p-3">
                        <div className="flex justify-end mb-4">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                href="https://manage.statuspage.io/login">
                                Update Status Page
                            </a>
                        </div>
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-md font-medium px-3 py-2 border-r text-left">
                                        Service
                                    </th>
                                    <th scope="col" className="text-md font-medium px-3 py-2 border-r text-left">
                                        Status
                                    </th>
                                    <th scope="col" className="text-md font-medium px-3 py-2 border-r text-left">
                                        Load Time
                                    </th>
                                    <th scope="col" className="text-md font-medium px-3 py-2 border-r text-left">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={`border-b`} key="business-continuity">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Business Continuity Dashboard
                                    </td>
                                    <td className="text-md text-green-600 font-light px-3 py-2 whitespace-nowrap border-r">
                                        Online
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        -
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://portal.lsre.info/goto/W8TpSjjVk?orgId=1"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            View
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="builds">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Builds
                                    </td>
                                    <td className="text-md text-green-600 font-light px-3 py-2 whitespace-nowrap border-r">
                                        Online
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        -
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://console.cloud.google.com/cloud-build/builds;region=us-central1?project=locally-iac&pli=1"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            View
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="deploy-api-docs">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Deploy API Docs
                                    </td>
                                    <td className="text-md text-green-600 font-light px-3 py-2 whitespace-nowrap border-r">
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        -
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://deployapi.lsre.info/docs"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            View
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="logs">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Logs
                                    </td>
                                    <td className={`text-md font-light px-3 py-2 whitespace-nowrap text-green-600`}>
                                        Online
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        -
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.project_id%3D%22locally-prod-336418%22%0Aresource.labels.location%3D%22us-central1%22%0Aresource.labels.cluster_name%3D%22locally-prod-336418-cluster%22%0Aresource.labels.namespace_name%3D%22main%22%0A;timeRange=P1D;cursorTimestamp=2023-06-30T13:21:39.550588688Z?project=locally-prod-336418&authuser=0&pli=1"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            View
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="memcached">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Memcached
                                    </td>
                                    <td className={`text-md font-light px-3 py-2 whitespace-nowrap ${services.memcached.online ? 'text-green-600' : 'text-red-600'}`}>
                                        {services.memcached.online ? "Online" : "Offline"}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        {services.memcached.duration_in_seconds.toFixed(4)} seconds
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://console.cloud.google.com/memorystore/memcached/instances?referrer=search&project=locally-prod-336418"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            Console
                                        </a>
                                        &nbsp;|&nbsp;
                                        <a
                                            href="https://portal.lsre.info/d/AQxf3X-mk/memcached?orgId=1&refresh=10s"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            Grafana
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="mysql">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        MySQL
                                    </td>
                                    <td className={`text-md font-light px-3 py-2 whitespace-nowrap ${services.mysql.online ? 'text-green-600' : 'text-red-600'}`}>
                                        {services.mysql.online ? "Online" : "Offline"}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        {services.mysql.duration_in_seconds.toFixed(4)} seconds
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://console.cloud.google.com/sql/instances?project=locally-prod-336418"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            Console
                                        </a>
                                        &nbsp;|&nbsp;
                                        <a
                                            href="https://portal.lsre.info/d/43IcDWDVz/mysql?orgId=1&refresh=10s"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            Grafana
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="queue-dashboard">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Queue Dashboard
                                    </td>
                                    <td className="text-md text-green-600 font-light px-3 py-2 whitespace-nowrap border-r">
                                        Online
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        -
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://portal.lsre.info/goto/-h2rSCCVz?orgId=1"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            View
                                        </a>
                                    </td>
                                </tr>
                                <tr className={`border-b`} key="redis">
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                        Redis
                                    </td>
                                    <td className={`text-md font-light px-3 py-2 whitespace-nowrap ${services.redis.online ? 'text-green-600' : 'text-red-600'}`}>
                                        {services.redis.online ? "Online" : "Offline"}
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        {services.redis.duration_in_seconds.toFixed(4)} seconds
                                    </td>
                                    <td className="text-md text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                        <a
                                            href="https://console.cloud.google.com/memorystore/redis/instances?referrer=search&project=locally-prod-336418"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            Console
                                        </a>
                                        &nbsp;|&nbsp;
                                        <a
                                            href="https://portal.lsre.info/d/RpSjVqWMz/redis-overview?orgId=1&refresh=10s"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500 hover:text-blue-700">
                                            Grafana
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Service Status</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div>
                <div className="overflow-hidden p-3">
                    {(spinner)
                        ? <Spinner />
                        : "No Service Status Found"
                    }
                </div>
            </div>
        </div>
    )
};

export default ServiceStatus;
